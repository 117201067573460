exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-faqs-js": () => import("./../../../src/templates/faqs.js" /* webpackChunkName: "component---src-templates-faqs-js" */),
  "component---src-templates-how-js": () => import("./../../../src/templates/how.js" /* webpackChunkName: "component---src-templates-how-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-pricing-js": () => import("./../../../src/templates/pricing.js" /* webpackChunkName: "component---src-templates-pricing-js" */),
  "component---src-templates-review-js": () => import("./../../../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-why-js": () => import("./../../../src/templates/why.js" /* webpackChunkName: "component---src-templates-why-js" */)
}

